.icon {
  height: 16px;
  width: 16px;
}

.dot {
  height: 10px;
  width: 10px;
}

.signers {
  padding: 0;
}

.signers::before {
  content: '';
  position: absolute;
  border-left: 2px solid var(--color-border-light);
  left: 15px;
  top: 20px;
  height: calc(100% - 40px);
}

.signers :global .MuiListItem-root:first-of-type {
  padding-top: 0;
}

.signers :global .MuiListItem-root {
  padding-left: 0;
  padding-right: 0;
}

.signers :global .MuiListItemText-root {
  margin-top: var(--space-1);
  margin-bottom: var(--space-1);
}

.signers :global .MuiListItemIcon-root {
  color: var(--color-primary-main);
  justify-content: center;
  min-width: 32px;
  padding: var(--space-1) 0;
}
