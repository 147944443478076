.connectedContainer {
  display: flex;
  align-items: center;
}

.buttonContainer {
  display: flex;
  align-items: center;
  text-align: left;
  gap: var(--space-1);
  padding: 0 var(--space-2);
}

.popoverContainer {
  padding: var(--space-2);
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-1);
  border: 1px solid var(--color-border-light);
}

.largeGap {
  gap: var(--space-2);
}

.addressName {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.profileImg {
  border-radius: var(--space-2);
  width: 32px;
  height: 32px;
}

.profileData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rowContainer {
  align-self: stretch;
  margin-left: calc(var(--space-2) * -1);
  margin-right: calc(var(--space-2) * -1);
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--color-border-light);
  padding: 4px var(--space-2);
  margin-top: -2px;
}

.row:last-of-type {
  border-bottom: 1px solid var(--color-border-light);
}

.loginButton {
  min-height: 42px;
}

.loginError {
  width: 100%;
  margin: 0;
}

@media (max-width: 599.95px) {
  .socialLoginInfo > div > div {
    display: none;
  }

  .notConnected {
    display: none;
  }
}
