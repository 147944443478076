.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
}

.accountContainer {
  width: 100%;
  margin-bottom: var(--space-1);
}

.accountContainer > span {
  border-radius: 8px 8px 0 0;
}

.addressContainer {
  border-radius: 0 0 8px 8px;
  padding: 12px;
  border: 1px solid var(--color-border-light);
  border-top: 0;
  font-size: 14px;
}

.warningButton {
  background-color: var(--color-warning-background);
  color: var(--color-warning-main);
  font-size: 12px;
}

.warningButton:global.MuiButton-root:hover {
  background-color: var(--color-warning-background);
}
