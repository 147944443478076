.button {
  padding: var(--space-2);
}

.buttonText {
  align-self: center;
  margin: auto;
  display: flex;
  gap: var(--space-1);
}

.fileIcon {
  display: flex;
  align-items: center;
  padding: var(--space-1);
  background-color: var(--color-background-main);
}

.card {
  padding: var(--space-3);
  gap: var(--space-2);
  display: flex;
  flex-direction: column;
}

.card :global .MuiCardHeader-root,
.card :global .MuiCardContent-root {
  padding: 0;
}

.cardHeader {
  text-align: left;
}
