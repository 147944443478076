.container {
  background-color: var(--color-info-background);
  padding: var(--space-2);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
}

.message {
  display: flex;
  align-items: flex-start;
  gap: var(--space-1);
}

.message button {
  vertical-align: baseline;
  text-decoration: underline;
}

.details {
  margin-top: var(--space-1);
  color: var(--color-primary-light);
  word-break: break-word;
}

.divider {
  margin-right: calc(-1 * var(--space-2));
  margin-left: calc(-1 * var(--space-2));
  border-color: var(--color-info-light);
}
